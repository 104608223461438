// Class for manipulation with the mockup list

export function filterMockups(mockups = [], filters = [], category) {
    if (!Array.isArray(mockups)) return null;
    const activeFilters = filters;
    const filteredMockups = [];
    // find matching mockups
    mockups.map((mockup) => {
        if (collectionMatch(category, mockup) && allFiltersMatch(activeFilters, mockup)) {
            return filteredMockups.push(mockup);
        }
        return null;
    });

    return filteredMockups;
}

function collectionMatch(category, mockup) {
    return !category || mockup.category === category;
}

function allFiltersMatch(filters, mockup) {
    if (!filters || filters.length === 0) return true;

    const defaultTags = ['photo', 'isolated', 'free', 'pro'];
    let matching = 0;
    // check if mockup has all the selected tags
    filters.map((tag) => {
        if (tag === 'photo' && !mockup.hasBackground) {
            matching++;
        }
        if (tag === 'isolated' && mockup.hasBackground) {
            matching++;
        }
        if (tag === 'free' && !mockup.isPremium) {
            matching++;
        }
        if (tag === 'pro' && mockup.isPremium) {
            matching++;
        }

        if (defaultTags.indexOf(tag) === -1 && mockup.tags && mockup.tags.indexOf(tag) > -1) {
            matching++;
        }
        return null;
    });

    return matching === filters.length;
}

/**
 * function that orders all the mockups by release date
 * @param mockups
 * @param property
 */
export function orderMockups(mockups = [], property, showFreeFirst) {
    const mockupList = [...mockups];

    let collator = null;
    try {
        collator = new Intl.Collator(undefined, { numeric: true, sensitivity: 'base' });
    } catch (e) {
        return null;
    }

    if (property === 'publishDate') {
        mockupList.sort((a, b) => {
            const x = a.publishDate;
            const y = b.publishDate;

            const n = y - x;
            if (n !== 0) {
                return n;
            }

            if (collator) {
                return collator.compare(a.id, b.id);
            }
            return a.id - b.id;
        });
    }

    mockupList.sort((a, b) => {
        const x = a[property] ? a[property] : 0;
        const y = b[property] ? b[property] : 0;

        const n = y - x;
        if (n !== 0) {
            return n;
        }

        if (collator) {
            return collator.compare(a.id, b.id);
        }
        return a.id - b.id;
    });
    if (showFreeFirst) {
        return putFreeMockupsOnTop(mockupList);
    }
    return mockupList;
}

function putFreeMockupsOnTop(mockups) {
    const free = [];
    const premium = [];

    mockups.map((mockup) => {
        if (mockup.isPremium) {
            premium.push(mockup);
        } else {
            free.push(mockup);
        }
        return null;
    });

    if (free.length < 12) {
        return [...free, ...premium];
    }

    const result = [];

    for (let i = 0; i < free.length; i++) {
        result.push(free[i]);

        const coef = getFreeOrderCoef();

        if (i % coef === 0 && premium.length > 0) {
            result.push(premium[0]);
            premium.shift();
        }
    }

    return [...result, ...premium];
}

function getFreeOrderCoef() {
    return Math.floor(Math.random() * Math.floor(2)) + 4;
}

/**
 * function that orders all the mockups by release date
 * @param mockups
 * @param property
 */
export function paginateMockups(mockups = [], page, perPage) {
    const mockupList = [...mockups];
    let start = 0;
    const end = perPage;

    if (page || parseInt(page) > 0) {
        start = parseInt(page) * perPage - perPage;
    }

    return mockupList.splice(start, end);
}
