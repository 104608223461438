export const PREVIEW_WIDTH = 366;
export const PREVIEW_HEIGHT = 274;

export const SIZES_WITH_SIDEBAR = `
        367px,
        (max-width: 1920px) calc(23vw - 2*9px),
        (max-width: 1530px) calc(31vw - 2*9px),
        (max-width: 1530px) calc(31vw - 2*9px),
        (max-width: 922px) calc(31vw - 2*9px),
        (max-width: 768px)  calc(46vw - 2*4px)`;

export const SIZES_WITHOUT_SIDEBAR = `
        295px,
        (max-width: 1392px) calc(23vw - 2*9px),
        (max-width: 1200px) calc(31vw - 2*9px),
        (max-width: 768px)  calc(46vw - 2*4px)`;

export function getImgixParams(mockupCategory) {
    const params = {
        crop: 'center',
        fit: 'crop',
        auto: 'compress',
        q: 38
    };

    // portrait mockups has to be cropped from the top
    if (mockupCategory === 'apparel') {
        params.crop = 'faces,top';
        params.fit = 'crop';
    }
    return params;
}
