import React, {useState} from 'react';
import PropTypes from 'prop-types';
import Link from '../../Base/LinkLocalised/LinkLocalised';
import styles from './CollectionPreview.module.scss';
import Icon from '../../Base/Icons/Icon';
import CollectionMockupImage from "./CollectionMockupImage";
import {FormattedMessage} from "react-intl";

const CollectionOverlay = (props) => {
    return (
        <div className={`${styles.overlayWrap} ${props.className}`}>
            <div className={styles.overlay} />
            {props.children}
        </div>
    );
}

const CollectionPreview = (props) => {
    const {
        collection,
        handleUpdateMockups,
        handleCollectionEmpty,
        mockupId,
        mockupThumb,
        isCondense,
        maxCountMockups,
        isLoading
    } = props;

    const findMockup = collection.mockups.findIndex((shortId) => shortId === mockupId);
    const imagesCount = 4;

    const [isActive, setIsActive] = useState();

    function getCollectionUrl() {
        return `/user-collection/${collection.timestamp}`;
   }

   const getMockupThumb = (id) => {
        if (collection.mockupsList) {
            const mockupThumb = Object.values(collection.mockupsList).find((obj) => {
                return obj.shortId === id;
            });

            return mockupThumb?.thumb ?? null;
        }

        return null;
   }

   function getLastMockups() {

        const getCollectionsMockups = [...collection.mockups];

       if (getCollectionsMockups.length > imagesCount) {
           return getCollectionsMockups.slice(Math.max(getCollectionsMockups.length - imagesCount, 1)).reverse();
       }

       return getCollectionsMockups.reverse();
   }

   function getLastMockup() {

        if (isActive === true && mockupId && findMockup < 0) {
            return mockupThumb;
        }

        if (collection.mockups.length > 1) {
            return getMockupThumb(collection.mockups[collection.mockups.length - 1]);
        }

        if (collection.mockups.length === 1) {
            return getMockupThumb(collection.mockups[0]);
        }

        return null;
   }

   function getClassName() {
       if (isCondense) {
           if (collection.mockups.length >= maxCountMockups && findMockup < 0) {
               return styles.full;
           }

           return findMockup >= 0 ? styles.collected : styles.collect;
       }

       return styles.noPadding;
   }

   const handleActive = (e) => {

        if (findMockup < 0) {
            setIsActive(true);
        } else {
            setIsActive(false);
        }

       if (handleUpdateMockups && collection.mockups.length >= maxCountMockups && findMockup >= 0) {
           return handleUpdateMockups(e);
       }

       if (handleUpdateMockups && collection.mockups.length < maxCountMockups && findMockup < 0 || handleUpdateMockups && collection.mockups.length < maxCountMockups && findMockup >= 0) {
           return handleUpdateMockups(e);
       }

       if (handleCollectionEmpty && collection.mockups.length === 0) {
           return handleCollectionEmpty(e);
       }
   }

    /// ////////
    // RENDERS
    /// ////////

    const renderCollectionHover = () => {
        if (findMockup >= 0 || isActive && findMockup < 0) {
            return (
                <CollectionOverlay className={`${isActive ? styles.active : null}`}>
                    <Icon name="Check" width={20} height={20} color="white" />
                    <Icon name="Minus" width={20} height={20} color="white" />
                </CollectionOverlay>
            );
        }

        if (collection.mockups.length >= maxCountMockups && findMockup < 0) {
            return (
                <CollectionOverlay>
                    <span className='font-bold text-center'><FormattedMessage defaultMessage="Collection is full" /></span>
                </CollectionOverlay>
            );
        }

        return (
            <CollectionOverlay>
                <Icon name="Plus" width={20} height={20} color="white" />
            </CollectionOverlay>
        );
    }

    const renderMockupsCount = () => {
        if (collection.mockups.length > imagesCount) {
            return (
                <CollectionOverlay>
                    <span className='font-bold'>+&nbsp;{collection.mockups.length - imagesCount}</span>
                </CollectionOverlay>
            );
        }

        return null;
    }

    const renderImages = () => {
       if (handleUpdateMockups) {
           const lastMockup = getLastMockup();
           return (
               <div className={`${styles.aspectRation} ${styles.imageBox}`}>
                   {renderCollectionHover()}
                   <CollectionMockupImage
                       mockupThumb={lastMockup}
                       previewWidth={180}
                       previewHeight={130}
                       title={collection.title}
                       setIcon
                       isNotLazy
                   />
               </div>
           );
       }

        const lastMockups = getLastMockups();

        return (
          <div className={styles.imagesHolder}>
              <div className={`${styles.mainImage} ${styles.imageBox}`}>
                  <CollectionMockupImage
                      mockupThumb={getMockupThumb(lastMockups[0])}
                      previewWidth={200}
                      previewHeight={227}
                      title={collection.title}
                      setIcon
                  />
              </div>
              <div className={styles.subImages}>
                  <div className={`${styles.aspectRation} ${styles.imageBox}`}>
                      <CollectionMockupImage
                          mockupThumb={getMockupThumb(lastMockups[1])}
                          previewWidth={100}
                          previewHeight={72}
                          title={collection.title}
                      />
                  </div>
                  <div className={`${styles.aspectRation} ${styles.imageBox}`}>
                      <CollectionMockupImage
                          mockupThumb={getMockupThumb(lastMockups[2])}
                          previewWidth={100}
                          previewHeight={72}
                          title={collection.title}
                      />
                  </div>
                  <div className={`${styles.aspectRation} ${styles.imageBox}`}>
                      {renderMockupsCount()}
                      <CollectionMockupImage
                          mockupThumb={getMockupThumb(lastMockups[3])}
                          previewWidth={100}
                          previewHeight={72}
                          title={collection.title}
                      />
                  </div>
              </div>
          </div>
        );
    }

    const renderTitle = () => {
        return (
            <h3 className={styles.content}>
                <span className={`font-bold ${styles.title}`}>{collection.title}</span>
                <span className={styles.mockupsCount}>{collection.mockups.length}</span>
            </h3>
        );
    }

    const renderPreviewContent = () => {

       if (handleUpdateMockups || handleCollectionEmpty && collection.mockups.length === 0) {
           return (
             <button onClick={(e) => handleActive(e)} disabled={isLoading && isActive} id={collection.timestamp}>
                 {renderImages()}
                 {renderTitle()}
             </button>
           );
       }

        return (
            <Link to={getCollectionUrl()}>
                {renderImages()}
                {renderTitle()}
            </Link>
        );

    }

    return (
      <article className={`${styles.collectionPreview} ${getClassName()} `}>
          <div data-cy="collectionPreview">
              {renderPreviewContent()}
          </div>
      </article>
    );
}

CollectionPreview.propTypes = {
    collection: PropTypes.object.isRequired,
    handleUpdateMockups: PropTypes.func,
    handleCollectionEmpty: PropTypes.func,
    mockupId: PropTypes.string,
    mockupThumb: PropTypes.string,
    isCondense: PropTypes.bool,
    maxCountMockups: PropTypes.number.isRequired,
    isLoading: PropTypes.bool
}

export default CollectionPreview;
