import React from 'react';
import Button from "../../Base/Button/Button";
import Icon from "../../Base/Icons/Icon";
import {FormattedMessage} from "react-intl";
import PropTypes from "prop-types";
import styles from "./ToolBar.module.scss";

const ToolBar = (props) => {
    const {isDetail, onModalCreate, onModalRename, onModalRemove, collections, maxCountCollections} = props;

    const renderButtons = () => {
        if (isDetail) {
            return (
                <>
                    <Button design="default" size="xlarge" className={styles.rename} onClick={onModalRename}>
                        <span><Icon name="Edit" size="md" /></span>
                        <span><FormattedMessage defaultMessage="Rename" /></span>
                    </Button>

                    <Button design="default" size="xlarge" onClick={onModalRemove}>
                        <span><Icon name="Trash" size="md" /></span>
                    </Button>
                </>
            );
        }

        if (collections < maxCountCollections) {
            return (
                <Button design="dashed" size="xlarge" className={styles.create} onClick={onModalCreate}>
                    <span><Icon name="Plus" width={10} height={10} /></span>
                    <span><FormattedMessage defaultMessage="Create new collection" /></span>
                </Button>
            );
        }

        return (
            <>
                <FormattedMessage defaultMessage="You have reached the maximum number of collections:" />
                <span className="font-bold">&nbsp;{maxCountCollections}</span>
            </>
        );
    };

    return (
      <div className={styles.toolBar}>
          {renderButtons()}
      </div>
    );
};

ToolBar.propTypes = {
  isDetail: PropTypes.bool,
  onModalCreate: PropTypes.func,
  onModalRename: PropTypes.func,
  onModalRemove: PropTypes.func,
  collections: PropTypes.number,
  maxCountCollections: PropTypes.number
}

export default ToolBar;
