import React from 'react';
import PropTypes from 'prop-types';
import styles from "./CollectionModal.module.scss";
import Modal, { ModalScroll } from '../../Base/Modal/Modal';
import Heading from "../../Base/Heading/Heading";
import CollectionMockupImage from "../../List/CollectionPreview/CollectionMockupImage";

const CollectionModal = (props) => {
    const {
        isModalOpen,
        isModalClose,
        modalTitle,
        size,
        children,
        mockupId,
        mockupThumb
    } = props;

    const renderModalContent = () => {
        if (size === "medium") {
            return (
                <div className={styles.wrapper}>
                    <div className={styles.left}>
                        <CollectionMockupImage
                            mockupId={mockupId}
                            mockupThumb={mockupThumb}
                            previewWidth={450}
                            previewHeight={600}
                            title={modalTitle}
                        />
                    </div>
                    <div className={styles.right}>
                        <ModalScroll>
                            <Heading level={3} className="text-center m-b-sm">
                                {modalTitle}
                            </Heading>
                            {children}
                        </ModalScroll>
                    </div>
                </div>
            );
        }

        return (
            <ModalScroll>
                <Heading level={3} className="text-center m-b-sm">
                    {modalTitle}
                </Heading>
                {children}
            </ModalScroll>
        );
    };

    return (
        <Modal
            isOpen={isModalOpen}
            modalLabel={modalTitle}
            onClose={isModalClose}
            size={size}
            isCentered
        >
            {renderModalContent()}
        </Modal>
    );
};

CollectionModal.propTypes = {
    isModalOpen: PropTypes.bool.isRequired,
    isModalClose: PropTypes.func.isRequired,
    modalTitle: PropTypes.string.isRequired,
    size: PropTypes.string,
    mockupId: PropTypes.string,
    mockupThumb: PropTypes.string
};

export default CollectionModal;
