import Skeleton, { SkeletonTheme } from 'react-loading-skeleton';
import React from 'react';
import styles from './Skeleton.module.scss';
import PropTypes from "prop-types";

const MockupListSkeleton = (props) => {

    const renderSkeletonsMockupList = (numrows) => {
        const rows = [];
        for (let i = 0; i < numrows; i++) {
            rows.push(
                <div className={styles.collection} key={i}>
                    <div className={`${styles.featuredMockups} ${props.isHalf ? styles.featuredMockupsHalf : null}`}>
                        <Skeleton height="0" width="100%" count={4} />
                    </div>
                </div>
            );
        }

        return rows;
    };

    const renderSkeletonsAllMockupsList = (numrows) => {
        const rows = [];
        for (let i = 0; i < numrows; i++) {
            rows.push(
                <div className={styles.collection} key={i}>
                    <div className={styles.collectionTitle}>
                        <Skeleton height="35px" width="300px" />
                    </div>
                    <div className={styles.collectionDescription}>
                        <Skeleton width="100%" height="100%" />
                    </div>
                    <div className={styles.featuredMockups}>
                        <Skeleton height="0" width="100%" count={8} />
                    </div>
                </div>
            );
        }

        return rows;
    };

    return <SkeletonTheme>{props.isSimpleList ? renderSkeletonsMockupList(1) : renderSkeletonsAllMockupsList(6)}</SkeletonTheme>;
};

MockupListSkeleton.propTypes = {
    isSimpleList: PropTypes.bool,
    isHalf: PropTypes.bool
};

export default MockupListSkeleton;
