/**
 * Function to download a file from URL
 * @param url
 */
export default function saveFile(url) {
    const link = document.createElement('a');
    link.href = url;
    link.setAttribute('download', '');
    // Append to html link element page
    document.body.appendChild(link);
    // Start download
    link.click();
    // Clean up and remove the link
    link.parentNode.removeChild(link);
}
