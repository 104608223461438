import React, {useReducer} from 'react';
import PropTypes from 'prop-types';
import Icon from '../../Base/Icons/Icon';
import {defineMessages, FormattedMessage, injectIntl} from 'react-intl';
import Button from '../../Base/Button/Button';
import form from "../../../styles/modules/Forms.module.scss";
import styles from "./CollectionModal.module.scss";
import useUserCollections from "../../../hooks/useUserCollections";

const FieldWrapper = (props) => (
    <div className={form.inputIconWrapper}>
        <div className={form.inputIcon}>
            <Icon name="Image" size="sm" color="grey" />
        </div>
        {props.children}
    </div>
);

const CollectionForm = (props) => {
    const {intl} = useUserCollections();

    const initialState = {
        inputName: `${props.oldCollection ? props.oldCollection : ''}`,
        isNameValid: !!props.oldCollection
    };

    const [state, setState] = useReducer(
        (state, newState) => ({ ...state, ...newState }),
        initialState
    );

    const onNewCollectionChange = (e) => {
        e.preventDefault();
        setState({
            inputName: e.target.value
        });

        if (e.target.value) {
            setState({
                isNameValid: true
            });
        } else {
            setState({
                isNameValid: false
            });
        }
    };

    const messages = defineMessages({
        collectionForm: {
            defaultMessage: 'Collection name',
            id: 'global.form.collectionForm'
        }
    });

    const handleSubmit = (e) => {
        e.preventDefault();
        if (state.isNameValid) {
            props.onFormSubmit(state.inputName);
            setState({
                isNameValid: false
            });
        } else {
            setState({
                isNameValid: false
            });
        }
    };

    function modalClose() {
        setTimeout(() => {
            props.isModalClose();
        }, 300);
    }

    const renderSubmitText = () => {
        if (props.success === true) {
            return (
                <FormattedMessage defaultMessage="Success" />
            );
        }

        if (props.isRenameType) {
            return (
                <FormattedMessage defaultMessage="Rename collection" />
            );
        }

        return (
            <FormattedMessage defaultMessage="Create collection" />
        );
    }

    const renderError = () => {
      if (props.success === true) {
          return (
              <div className={`${styles.messages} ${styles.success}`}>
                  <Icon name="Check" size="sm" />
                  <FormattedMessage defaultMessage="Everything went well" />
                  {modalClose()}
              </div>
          );
      }

      if (props.success === false) {
          return (
              <div className={`${styles.messages} ${styles.error}`}>
                  <Icon name="Close" size="sm" color="red"/>
                  <FormattedMessage defaultMessage="The event was not successful" />
              </div>
          );
      }

      return null;
    };

    const renderButtons = () => {
        return (
            <div className={styles.buttonsGroup}>
                <Button design="default" size="large" onClick={props.isModalClose}>
                    <FormattedMessage defaultMessage="Cancel" />
                </Button>
                <Button type="submit" design="confirm" size="large" isLoading={props.isLoading} disabled={!state.isNameValid}>
                    {renderSubmitText()}
                </Button>
            </div>
        );
    }

    return (
        <div>
            <form onSubmit={(e) => handleSubmit(e)}>
                <label className={form.inputLabel} htmlFor="name">
                    <FormattedMessage defaultMessage="Name" />
                    <FieldWrapper>
                        <input type="text" id="name" onChange={e => onNewCollectionChange(e)} value={state.inputName} placeholder={intl.formatMessage(messages.collectionForm)} className={`${form.inputLarge} ${styles.inputModal}`} />
                    </FieldWrapper>
                </label>
                <div>{renderButtons()}</div>
                {renderError()}
            </form>
        </div>
    );
};

export default injectIntl(CollectionForm);

CollectionForm.propTypes = {
    onFormSubmit: PropTypes.func.isRequired,
    success: PropTypes.bool.isRequired,
    isModalClose: PropTypes.func.isRequired,
    oldCollection: PropTypes.string,
    isRenameType: PropTypes.bool,
    isLoading: PropTypes.bool.isRequired
};
