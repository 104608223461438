import {
    FETCH_USER_COLLECTIONS,
    FETCH_USER_COLLECTION,
    NEW_USER_COLLECTION,
    EDIT_USER_COLLECTION,
    REMOVE_USER_COLLECTION,
    UPDATE_MOCKUPS_COLLECTION
} from './actionTypes';
import { userCollectionsService } from '../apis/ServiceClient';

export function fetchUserCollections(username) {
    const request = userCollectionsService.get(`user-collections/${username}`);
    return {
        type: FETCH_USER_COLLECTIONS,
        payload: request
    };
}

export function fetchUserCollectionMockups(username, timestamp) {
    const request = userCollectionsService.get(`user-collections/${username}/${timestamp}`);
    return {
        type: FETCH_USER_COLLECTION,
        payload: request
    };
}

export function addUserCollection(username, title) {
    const request = userCollectionsService.post(`user-collections/${username}`, title);
    return {
        type: NEW_USER_COLLECTION,
        payload: request
    };
}

export function editUserCollection(username, timestamp, title) {
    const request = userCollectionsService.patch(
        `user-collections/${username}/${timestamp}`,
        title
    );
    return {
        type: EDIT_USER_COLLECTION,
        payload: request
    };
}

export function removeUserCollection(username, timestamp) {
    const request = userCollectionsService.delete(`user-collections/${username}/${timestamp}`);
    return {
        type: REMOVE_USER_COLLECTION,
        payload: request
    };
}

export function updateMockupsCollection(username, timestamp, data) {
    data.type = data.type.toLowerCase();
    const request = userCollectionsService.post(`user-collections/${username}/${timestamp}`, data);
    return {
        type: UPDATE_MOCKUPS_COLLECTION,
        payload: request
    };
}
