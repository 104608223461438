import toQueryString from '../utils/toQueryString';
import { useRouter } from 'next/router';

const useQueryUrl = () => {
    const router = useRouter();

    const makeFilter = (items) => {
        const setQuery = { ...router.query };

        delete setQuery.slug;
        delete setQuery.page;

        const result = { qs: toQueryString({ ...setQuery, filter: items }) };

        if (router.asPath.includes('?')) {
            const pathArray = router.asPath.split('?');
            pathArray.pop();
            result.rootPath = pathArray.join('/');
        } else {
            result.rootPath = router.asPath;
        }

        return result;
    };

    const makeCategory = (items, pathname) => {
        const pathArray = pathname ? [pathname] : router.pathname.split('/');
        const setQuery = { ...router.query };

        delete setQuery.slug;
        delete setQuery.page;
        delete setQuery.filter;
        delete setQuery.search;

        const result = { qs: toQueryString({ ...setQuery }) };

        if (pathArray.length > 2) {
            pathArray.pop();
        }
        result.rootPath = `${pathArray.join('/')}/${items}`;

        return result;
    };

    const makePage = (items) => {
        const { asPath } = router;
        const setQuery = { ...router.query };
        delete setQuery.slug;

        const result = { qs: toQueryString({ ...setQuery, page: items }) };

        if (asPath.includes('?')) {
            const pathArray = asPath.split('?');

            pathArray.pop();
            result.rootPath = pathArray.join('/');
        } else {
            result.rootPath = asPath;
        }

        return result;
    };

    return {
        makeQueryUrl: (items, type, pathname) => {
            if (!type) {
                throw new Error(
                    'the parameter "type" is required and expects a value "filter" || "category" || "page"!'
                );
            }

            const urlByType = {
                filter: makeFilter(items),
                category: makeCategory(items, pathname),
                page: makePage(items)
            };

            const res = urlByType[type];
            return !res.qs ? res.rootPath : `${res.rootPath}${res.qs}`;
        }
    };
};

export default useQueryUrl;
