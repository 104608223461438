/**
 *
 * @param obj
 * @param obj
 */
export default function toQueryString(obj) {
    const qs = [];
    Object.keys(obj).map(key => {
        if (obj[key]) {
            const keyName = encodeURIComponent(key);
            const value = Array.isArray(obj[key]) ? obj[key].toString() : obj[key];
            if (value && ((Number.isInteger(value) && value > 0) || value.length > 0)) {
                qs.push(keyName + '=' + value);
            }
        }
    });
    if (qs.length === 0) return '';
    return '?' + qs.join('&');
}
