import React, { useMemo } from 'react';
import PropTypes from 'prop-types';
import { PUBLIC_MOCKUP_IMGIX } from '../../../utils/variables';
import Imgix from 'react-imgix';
import { SIZES_WITH_SIDEBAR } from '../MockupPreview/helpers';
import Icon from '../../Base/Icons/Icon';

const CollectionMockupImage = (props) => {
    const { mockupId, mockupThumb, locale, previewWidth, previewHeight, title, setIcon, isNotLazy } = props;
    const thumbId = mockupThumb || `${mockupId}_pr`;

    const loc = locale || 'en';
    const imageUrl = `${PUBLIC_MOCKUP_IMGIX}/${thumbId}_${loc}.jpg?w=${previewWidth}?h=${previewHeight}`;

    const params = {
        crop: 'top,center',
        fit: 'crop',
        auto: 'compress',
        'max-w': previewWidth * 2
    };

    return useMemo(() => {
        if (isNotLazy === true && (mockupId || mockupThumb)) {
            return (
                <figure title={title}>
                    <Imgix
                        src={`${imageUrl}`}
                        sizes={SIZES_WITH_SIDEBAR}
                        htmlAttributes={{
                            alt: title
                        }}
                        width={previewWidth}
                        height={previewHeight}
                        imgixParams={params}
                    />
                </figure>
            );
        }

        if (mockupId || mockupThumb) {
            return (
                <figure title={title}>
                    <Imgix
                        className="lazyload"
                        src={`${imageUrl}`}
                        sizes={SIZES_WITH_SIDEBAR}
                        attributeConfig={{
                            src: 'data-src',
                            srcSet: 'data-srcset',
                            loading: 'lazy'
                        }}
                        htmlAttributes={{
                            src: `${imageUrl}?blur=200&px=8&fit=crop&q=1`,
                            alt: title
                        }}
                        width={previewWidth}
                        height={previewHeight}
                        imgixParams={params}
                    />
                </figure>
            );
        }

        if (setIcon) {
            return <Icon name="Image" width={40} height={40} color="grey" />;
        }

        return null;
    }, [imageUrl, params, setIcon, title, isNotLazy]);
};

CollectionMockupImage.propTypes = {
    mockupId: PropTypes.string,
    mockupThumb: PropTypes.string,
    locale: PropTypes.string,
    previewWidth: PropTypes.number,
    previewHeight: PropTypes.number,
    title: PropTypes.string,
    setIcon: PropTypes.bool,
    isNotLazy: PropTypes.bool
};

export default CollectionMockupImage;
